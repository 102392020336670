import React from 'react';
import { Container, Grid, Image } from 'semantic-ui-react';
import {
  BigPlayButton,
  ControlBar,
  CurrentTimeDisplay,
  PlaybackRateMenuButton,
  Player,
  TimeDivider,
  VolumeMenuButton
} from 'video-react';

import Header from '../components/header';
import Layout from '../components/layout';
import KetButton from '../components/ket-button';
import Menu from '../components/menu';
import SEO from '../components/seo';

import brassClipImage11 from '../images/brass-clip-pr21-512.png';
import brassClipImage12 from '../images/brass-clip-sc30-512.png';
import brassClipImage21 from '../images/brass-clip-pr21-4colors-512x384.png';
import brassClipImage22 from '../images/brass-clip-sc30-4colors-512x384.png';

import posterImage from '../images/poster-image.jpg';

const BrassClip = () => (
  <>
    <Layout>
      <SEO title="Brass-Clip" />
      <Header />
      <Menu />
      <Container className="container__ket myriad-regular" text textAlign="center">
        The Brass-Clip firearm accessory comes in two formats, designed to attach to AR
        and rifle scopes or Picatinny rails. Attachment is simple&mdash;just slip a hat or
        the Brass-Deflector onto this durable clip and snap onto your firearm&mdash;no
        mounts necessary. In addition to easily blocking spent ammo from fellow shooters,
        this solution drops brass casings nearby for quick collection.
      </Container>
      <div className="container__ket-blue">
        <Grid stackable padded>
          <Grid.Row centered>
            <Grid.Column width="4" textAlign="center">
              <Image alt="Brass Clip PR21" src={brassClipImage11} />
            </Grid.Column>
            <Grid.Column width="4" textAlign="center">
              <Image alt="Brass Clip SC30" src={brassClipImage12} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width="4" textAlign="center">
              <Image alt="Brass Clip PR21 Colors" src={brassClipImage21} />
            </Grid.Column>
            <Grid.Column width="4" textAlign="center">
              <Image alt="Brass Clip SC30 Colors" src={brassClipImage22} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <div className="container__ket">
        <Grid className="product-video" stackable>
          <Grid.Row centered>
            <Grid.Column width="8" textAlign="center">
              <Player poster={posterImage}>
                <BigPlayButton position="center" />
                <source src="../videos/brass-clip-video.mp4" />
                <ControlBar>
                  <VolumeMenuButton />
                  <CurrentTimeDisplay disabled />
                  <TimeDivider disabled />
                  <PlaybackRateMenuButton disabled />
                </ControlBar>
              </Player>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <Container className="container__ket" text textAlign="center">
        <a
          href="https://www.amazon.com/dp/B07VQ2TG2J/ref=sr_1_10?keywords=brass-deflector&qid=1564271022&s=gateway&sr=8-10"
          rel="noreferrer noopener"
          target="_blank"
        >
          <KetButton>See Price on Amazon</KetButton>
        </a>
      </Container>
    </Layout>
  </>
);

export default BrassClip;
